import { PermissionGroup } from "@/react/types";

const projectPermissions: PermissionGroup = {
  name: "Projects",
  key: "projects",
  description: null,
  permissions: {
    view: {
      name: "View Scope Of Work",
      key: "scope_of_work_permission_view",
      description: null,
    },
    manage: {
      name: "Manage Scope Of Work",
      key: "scope_of_work_permission_manage",
      description: null,
    },
    hardware_order_approval: {
      name: "Order Approval",
      key: "hardware_orders_permission_order_approval",
      description: null,
      hidden: true,
    },
    hardware_manage_orders: {
      name: "Manage Orders",
      key: "hardware_orders_permission_manage_orders",
      description: null,
      hidden: true,
    },
  },
};

export default projectPermissions;
