import { useQuery } from "@tanstack/react-query";

import { instance } from "@/react/hooks/api";
import { useIsAuthenticated } from "@/react/hooks/useIsAuthenticated";
import { User } from "@/react/types/users/user";

const API_NAMESPACE = "support-api";

export const useCurrentUserQuery = () => {
  const { isAuthenticated } = useIsAuthenticated();
  return useQuery(
    ["current-user"],
    async () => {
      const { data } = await instance({
        apiNamespace: API_NAMESPACE,
      }).get<User>("/users/me");
      return data;
    },
    {
      enabled: isAuthenticated,
      // We can safely cache this data forever and manually invalidate the cache when the user edits their profile.
      staleTime: Infinity,
    }
  );
};
