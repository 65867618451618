import { useEffect, useState } from "react";

import { getAccessToken } from "@/lib/authentication";

/**
 * Hook to fetch current authentication status.
 */
export function useIsAuthenticated() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    getAccessToken().then(() => {
      setIsLoading(false);
      setIsAuthenticated(true);
    });
  });

  return { isAuthenticated, isLoading };
}
